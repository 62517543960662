import React, { useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import { Main, Works, Board, About, Error404 } from "pages";
import Header from "./core/Header";

import firefly from "js/util/firefly";
import $ from 'jquery';

import "css/style.css";
import "css/default.css";


const AppRouter = () => {
    const location = useLocation();
    const isHeaderNeed = !['/404'].includes(location.pathname);
    
    // 파티클 이펙트
    useEffect(() => {
        firefly();

        return () => {
            if ($.firefly && $.firefly.pause) {
                $.firefly.pause();
            }
        };
    }, []);

    // 우클릭 차단
    useEffect(() => {
        const handleContextMenu = (e) => {
            e.preventDefault();
        };
        document.addEventListener("contextmenu", handleContextMenu);
        return () => {
            document.removeEventListener("contextmenu", handleContextMenu);
        }
    }, []);
    
    return (
        <>
            {isHeaderNeed && <Header />}
            <Routes>
                {/* 메인 페이지 */}
                <Route index element={<Main />} />

                {/* 소개 페이지 */}
                <Route path="/about" element={<About />} />

                {/* 방명록 페이지지 */}
                <Route path="/board" element={<Board />} />

                {/* 작업물 리스트 페이지 */}
                <Route path="/works" element={<Works />} />

                {/* 404 에러 페이지 */}
                <Route path="/404" element={<Error404 />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
                
            </Routes>
        </>
    );
}

export default AppRouter;