import React, { useState } from "react"
import { Helmet } from "react-helmet-async";
import worksData from "core/works.json";

function Main() {
    const [hoveredWorkId, setHoveredWorkId] = useState(null);

    const linesCount = 3;
    const worksLines = Array.from({ length: linesCount }, () => []);
    const placeholderImage = "https://via.placeholder.com/300x200?text=No+Image"; // 플레이스홀더 URL

    const handleVideoError = (e) => {
        const videoElement = e.target;
        const fallbackImage = videoElement.getAttribute("data-thumb");
        videoElement.outerHTML = `<img src="${fallbackImage}" alt="Fallback" class="work-image" onError="this.src='${placeholderImage}'"/>`;
    };

    worksData.sort((a, b) => b.year - a.year);

    worksData.forEach((work, index) => {
        const lineIndex = index % linesCount;
        worksLines[lineIndex].push(work);
    });

    return (
        <>
            <Helmet>
              <title>294 - Works</title>
            </Helmet>
            
            <section id="works">

                        <main id="works-list">
                            <div
                                className={`works-list-grid ${hoveredWorkId ? "dimmed" : ""}`}
                            >
                                {worksLines.map((line, lineIndex) => (
                                    <div className="works-line" key={lineIndex}>
                                        {line.map((work) => (
                                            <div
                                                className={`works ${
                                                    hoveredWorkId  === work.id ? "active" : ""
                                                }`}
                                                key={work.id}
                                                onMouseEnter={() => setHoveredWorkId(work.id)}
                                                onMouseLeave={() => setHoveredWorkId(null)}
                                            >

                                                {work.videoUrl ? (
                                                    <video
                                                        src={work.videoUrl}
                                                        className="work-video"
                                                        autoPlay
                                                        loop
                                                        muted
                                                        playsInline
                                                        data-thumb={work.thumbUrl}
                                                        onError={handleVideoError}
                                                    ></video>
                                                ) : work.thumbUrl ? (
                                                    <img
                                                        src={work.thumbUrl}
                                                        alt={work.title}
                                                        className="work-image"
                                                        onError={(e) =>
                                                            (e.target.src = placeholderImage)
                                                        }
                                                    />
                                                ) : (
                                                    <img
                                                        src={placeholderImage}
                                                        alt="No content available"
                                                        className="work-image"
                                                    />
                                                )}

                                                {/* <div className="glitch"></div> */}
                                                <div className="work-title">{work.title}</div>
                                                <div className="work-year">{work.year}</div>

                                                <div className="gradient-bg"></div>
                                                {hoveredWorkId === work.id && (
                                                    <>
                                                        <div className="work-icons drop-icon">
                                                            {work.type !== "none" && (
                                                                <p className="work-type" id={work.type}>
                                                                    {work.type}
                                                                </p>
                                                            )}
                                                            {work.repoUrl !== "none" && (
                                                                <a
                                                                    href={work.repoUrl}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    aria-label="GitHub Repository"
                                                                    className="work-icon drop-icon"
                                                                >
                                                                    <i className="fab github fa-github"></i>
                                                                </a>
                                                            )}
                                                            {work.previewUrl !== "none" && (
                                                                <a
                                                                    href={work.previewUrl}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    aria-label="Preview"
                                                                    className="work-icon link drop-icon"
                                                                >
                                                                    <i class="far fa-link"></i>
                                                                </a>
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </main>
            </section>

        </>
    );
}

export default Main;

