import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import icons from "img/icons/header.svg";

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => setMenuOpen(!menuOpen);
    const location = useLocation();
    const isActive = (path) => location.pathname === path;
    const menuClick = () => {
        setMenuOpen(false); // 메뉴 닫기
    };

    return (
        <div className={`header-container ${menuOpen ? "open" : ""}`}>
            {/* 버튼 */}
            <div className="header-trigger" onClick={toggleMenu} aria-label="Toggle navigation">
                <div className="hamburger">
                    <span className={`line ${menuOpen ? "rotate-down" : ""}`}></span>
                    <span className={`line ${menuOpen ? "fade-out" : ""}`}></span>
                    <span className={`line ${menuOpen ? "rotate-up" : ""}`}></span>
                </div>
            </div>

            {/* 펼쳐지는 메뉴 */}
            {menuOpen && (
                <ul className="header-items">
                    <li className={`header-item ${isActive("/") ? "active" : ""}`}>
                        <Link to="/" onClick={menuClick}>
                            <svg className="icon">
                                <use href={`${icons}#icon-home`} />
                            </svg>
                        </Link>
                    </li>
                    <li className={`header-item ${isActive("/works") ? "active" : ""}`}>
                        <Link to="/works" onClick={menuClick}>
                            <svg className="icon">
                                <use href={`${icons}#icon-folder`} />
                            </svg>
                        </Link>
                    </li>
                    <li className={`header-item ${isActive("/about") ? "active" : ""}`}>
                        <Link to="/about" onClick={menuClick}>
                            <svg className="icon">
                                <use href={`${icons}#icon-profile`} />
                            </svg>
                        </Link>
                    </li>
                    <li className={`header-item ${isActive("/board") ? "active" : ""}`}>
                        <Link to="/board" onClick={menuClick}>
                            <svg className="icon">
                                <use href={`${icons}#icon-chat`} />
                            </svg>
                        </Link>
                    </li>
                    <li className={`header-item`}>
                        <Link to="https://blog.294.ink" onClick={menuClick}>
                            <svg className="icon">
                                <use href={`${icons}#icon-blog`} />
                            </svg>
                        </Link>
                    </li>
                </ul>
            )}
        </div>
    );
};

export default Header;
